@import './antd.css';
// @import '~antd/dist/antd.css';


/*
//trying to figure out this serif issue on the number 1's on the dashboard
//maybe self hosting is a fix?
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}
*/


:root {
  --app-height: 100vh;
}
body,
html,
h1,
h2,
h3,
h4 {
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  height: 100%;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding: 0;
  margin: 0;
}

/* .makeStyles-paper-11, */
/* .makeStyles-paper-11 h2, */
/* .MuiTypography-body1,
.MuiPaper-root, */

h1,
h2,
h3,
h4,
h5,
h6 {
  // text-transform: uppercase;
  color: #fff;
}

.MuiToolbar-regular {
  color: #d6efff;
}

.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover,
.MuiListItem-button:hover,
.MuiButtonBase-root:hover {
  color: #fff !important;
}

.account {
  display: flex;
  align-items: center;
}

.account > img,
.account > div {
  margin-right: 8px;
}

.wallet-button {
  color: white;
  font-size: 1rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}
.wallet-button:hover {
  color: #1d48b6 !important;
}

.navLink {
  color: white !important;
  &:hover {
    color: white !important;
  }
}

.shinyButton {
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;
  font-size: 1rem !important;
  padding-left: 5px;
  color: #1d48b6 !important;
}

.shinyButtonTertiary {
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;
  font-size: 1rem !important;
  padding-left: 5px;
  color: #dddfee !important;
  box-shadow: 0px 0px 5px 0px white;
}

.zrx {
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 35px;
  /* padding-left: 5px; */
  box-shadow: 0px 0px 13px 0px rgb(255, 68, 4, 0.75);
}

.tutorial {
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;
  font-size: 1rem !important;
  padding-left: 5px;
  background-color: #1d48b6 !important;
  color: #08090d !important;
  box-shadow: 0px 0px 5px 0px rgba(26, 107, 158, 1);
}

.shinyButtonDisabled {
  border-radius: 15px;

  color: grey !important;
  // box-shadow: 0px 0px 5px 0px grey;
}

.shinyButton:hover {
  background-color: #1d48b6 !important;
  color: #08090d !important;
}

.shinyButtonTertiary:hover {
  background-color: #dddfee !important;
  color: #08090d !important;
}

.tutorial:hover {
  background-color: #1d48b6 !important;
  color: #08090d !important;
  // box-shadow: 0px 0px 10px 0px rgba(26, 107, 158, 1);
}

.shinyButtonSecondary {
  padding-top: 5px;
  padding-bottom: 5px;

  // border-radius: 15px;
  font-size: 1rem !important;
  padding-left: 5px;
  background-color: #1d48b6 !important;
  color: #08090d !important;
  /* box-shadow: 0px 0px 5px 0px rgba(26, 107, 158, 1); */
}

.shinyButtonDisabledSecondary {
  border-radius: 15px;

  color: grey !important;
}

.shinyButtonSecondary:hover {
  background-color: #1d48b6 !important;
  color: #08090d !important;
}

.navTokenPrice {
  padding-top: 6px;
  padding-right: 12px;
  color: #fff;
}

.navTokenIcon {
  height: 30px !important;
  width: 30px !important;
  padding-top: 3px;
  padding-right: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: hidden;
}

.emp {
  background-image: url('./assets/img/emp-new.png');
  background-size: 38px 38px;
}

.emp-lp {
  background-image: url('./assets/img/unanimated-pair.png');
  background-size: 38px 38px;
}

.eshare-lp {
  background-image: url('./assets/img/reactor-lp.png');
  background-size: 38px 38px;
}

.bshare {
  background-image: url('./assets/img/eshare-final.png');
  background-size: 38px 38px;
}

.btc {
  background-image: url('./assets/img/eth-final.png');
  background-size: 40px 40px;
}

.bnb {
  background-image: url('./assets/img/bnb-final.png');
  background-size: 40px 40px;
}

.strategy {
  background-image: url(./assets/bg.png);
}

// .font-face-montserrat {
//   font-family: 'Montserrat';
// }

html,
body {
  font-family: 'Montserrat', sans-serif !important;
}

* {
  font-family: 'Montserrat', sans-serif !important;
}

body {
  background: url(./assets/bg.png) !important;
  background-size: cover !important;
  background-position: top center;
  color: #fff !important;
  background-repeat: no-repeat !important;
  // max-height: 100vh;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff !important;
}
// a:hover{
//   color: inherit !important;
// }

.MuiButton-root {
  //min-width: 160px !important;
  @media (max-width: 1366px) {
    min-width: unset !important;
  }
}
.MuiButtonBase-root {
  background: linear-gradient(270deg, #bfb5ff 0%, #aecaff 100%);
  border-radius: 19.25px;
  padding: 7px 30px !important;
  color: #000000 !important;
  border: 0;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-align: center;
  letter-spacing: 0.000666667px;
  cursor: pointer;
  box-shadow: none;
  transition: all 0.3s ease !important;
  &:hover {
    background: linear-gradient(270deg, #7664e6 0%, #81adff 100%) !important;
    color: #fff !important;
  }
  .MuiButton-label {
    font-weight: 700 !important;
    text-transform: capitalize;
    font-size: 15px;
  }
  .MuiButton-label {
    font-weight: 700;
  }
  &.shinyButtonDisabled {
    border: 1px solid rgba(176, 198, 255, 0.5) !important;
    background: transparent !important;
    color: rgba(176, 198, 255, 0.5) !important;
    border-radius: 4 !important;
    &:hover {
      border: 1px solid rgba(176, 198, 255, 0.5) !important;
      background: transparent !important;
      color: rgba(176, 198, 255, 0.5) !important;
    }
    cursor: not-allowed;
  }
}

.ant-tooltip {
  .ant-tooltip-content {
    border-radius: 10px;
    overflow: hidden;
  }
  .ant-tooltip-inner {
    padding: 12px 10px !important;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 15px !important;
    border: 1px solid #1f1e2b;
    background-color: #1c1c1c !important;
  }
}

.ant-carousel {
  position: relative;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit;
  left: 10px;
  z-index: 2;
  color: blueviolet;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  right: 10px;
  z-index: 2;
  color: blueviolet;
}

.ant-layout {
  background: transparent !important;
}

.display-layout {
  &.ant-layout {
    max-height: var(--app-height);
    overflow-y: scroll;
  }
}
.display-content {
  padding: 40px 30px;
  padding-bottom: 60px;
  @media screen and (max-width: 600px) {
    padding: 30px 0px !important;
  }
}
.ant-layout-sider {
  height: var(--app-height);
  position: sticky;
  top: 0;
}
.ant-layout-content {
  flex: none !important;
  min-height: unset !important;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

iframe {
  display: none !important;
}

.MuiButtonBase-root {
  background: linear-gradient(270deg, #bfb5ff 0%, #aecaff 100%) !important;
  border-radius: 19.25px !important;
  padding: 7px 30px !important;
  color: #000000 !important;
  border: 0;
  font-weight: 700;
  &:hover {
    background: linear-gradient(270deg, #7664e6 0%, #81adff 100%) !important;
    color: #fff !important;
  }
}

.display-content {
  min-height: var(--app-height) !important;
  // .btnotconnected {
  // }
}

.connectwalletmodal {
  .modalinner {
    width: calc(100vw - 240px);
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    text-align: center;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
    h2 {
      font-size: 18px;
      text-transform: capitalize;
    }
    .MuiList-root {
      display: flex;
      justify-content: center;
      @media (max-width: 550px) {
        flex-direction: column;
      }
      button {
        background: transparent !important;
        padding-top: 14px !important;
        &:hover {
          background: rgba(255, 255, 255, 0.07) !important;
        }
        .MuiButton-label {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            margin-bottom: 10px;
            margin-right: 0 !important;
            color: #fff;
          }
        }
      }
    }
  }
}

.sidebartoolbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-height: var(--app-height);
  overflow-y: scroll;
  margin-bottom: 0 !important;
  .sidebartoolbarinner {
    min-height: 760px;
    height: var(--app-height);
    flex-flow: unset;
    flex-direction: column;
    @media (max-width: 992px) {
      min-height: 660px !important;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.farmtop {
  margin-top: 38px !important;
  font-weight: 700 !important;
  font-size: 26px !important;
  line-height: 32px !important;
  /* identical to box height */

  letter-spacing: -1.48456px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 550px) {
    flex-direction: column;
    button {
      margin-top: 14px;
      align-items: flex-start;
    }
  }
  @media (max-width: 600px) {
    .MuiGrid-container {
      width: 100% !important;
      margin: 0px !important;
    }
  }
}

.cardheading {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  /* identical to box height */

  letter-spacing: -1.48456px;
}

.overview-row {
  margin: 20px 0;
  @media screen and (max-width: 992px) {
    .ant-col:first-child {
      margin-bottom: 14px;
    }
  }
}

// a{
//   color: #B1C7FF !important;
//   text-decoration: underline;
// }
.btn-outline {
  border: 1px solid #b0c6ff !important;
  background: transparent !important;
  color: #b0c6ff !important;
  border-radius: 4 !important;
  &:hover {
    border: 1px solid #b0c6ff !important;
    background: transparent !important;
    color: #b0c6ff !important;
  }
}
.btmaccountsidebarbtns {
  .btn-outline {
    margin-top: 14px;
    border-radius: 8px !important;
    width: 100%;
  }
}
.threetop-row {
  margin-bottom: 20px;
  .ant-col {
    .bx {
      background: rgba(117, 117, 117, 0.161686);
      border: 1.1px solid rgba(92, 105, 148, 0.11);
      padding: 14px 18px;
      border-radius: 14px;
      height: 100%;
      display: flex;
      align-items: center;
      min-height: 86px;
      h3 {
        margin-bottom: 0;
      }
      .left {
        margin-right: 20px;
        display: flex;
        align-items: center;
      }
      .right {
        font-weight: 400;
        font-size: 13.2px;
        line-height: 16px;
        letter-spacing: 0.000586667px;
        span {
          &.blue {
            color: #5d86ff;
            position: relative;
            top: -2px;
            left: 3px;
            font-weight: 400;
            font-size: 13.2px;
            line-height: 16px;
            letter-spacing: 0.000586667px;
          }
          &.green {
            color: #96e014;
            position: relative;
            top: -2px;
            left: 3px;
            font-weight: 400;
            font-size: 13.2px;
            line-height: 16px;
            letter-spacing: 0.000586667px;
          }
          &.orange {
            color: #FFB841;
            position: relative;
            top: -2px;
            left: 3px;
            font-weight: 400;
            font-size: 13.2px;
            line-height: 16px;
            letter-spacing: 0.000586667px;
          }
          &._bluedot {
            background: #5d86ff;
            width: 6px;
            height: 6px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 3px;
            position: relative;
            top: -4px;
          }
          &._greendot {
            background: #96e014;
            width: 6px;
            height: 6px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 3px;
            position: relative;
            top: -4px;
          }
          &._orangedot {
            background: #FFB841;
            width: 6px;
            height: 6px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 3px;
            position: relative;
            top: -4px;
          }
        }
        h3 {
          font-weight: 700;
          font-size: 26px;
          line-height: 32px !important;
          letter-spacing: -1.48456px;
          .small {
            color: #a5a5a5;
            font-weight: 400;
            font-size: 13.2px;
            line-height: 16px;
            /* identical to box height */

            letter-spacing: 0.000586667px;
          }
          &.blue {
            color: #5d86ff !important;
          }
          &.green {
            color: #96e014 !important;
          }
          &.orange {
            color: #FFB841 !important;
          }
          .green {
            color: #96e014;
            position: relative;
            top: -2px;
            left: 3px;
            font-weight: 400;
            font-size: 13.2px;
            line-height: 16px;
            letter-spacing: 0.000586667px;
          }
          .greendot {
            background: #96e014;
            width: 6px;
            height: 6px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 3px;
            position: relative;
            top: -4px;
          }
        }
      }
    }
    @media screen and (max-width: 992px) {
      margin-bottom: 14px;
    }
  }
}
.recharts-cartesian-axis-tick-value {
  fill: #fff !important;
  font-size: 10px;
}
.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  stroke: #fff !important;
}

.allpagebanner {
  position: relative;
  padding: 24px;
  border-radius: 20px;
  overflow: hidden;
  .videobg {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &::before {
    position: absolute;
    content: '';
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.0001) 100%);
    mix-blend-mode: normal;
    opacity: 0.6;
    z-index: -1;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // min-height:300px;
    height: 100%;
    max-width: 320px;
    h2 {
      text-transform: capitalize;
      font-weight: 700;
      font-size: 48.4px;
      line-height: 59px;
      letter-spacing: -2.76356px;
      margin-bottom: 10px;
    }
    p {
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: 0.000684444px;
      margin-bottom: 6px;
    }
    a {
      color: #b1c7ff;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.000666667px;
      text-decoration: underline;
    }
    .btm {
      margin-top: 160px;
      .txt {
        opacity: 0.5;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.000666667px;
      }
    }
    // @media (max-width: 1600px) {
    //   h2 {
    //     font-size: 28px !important;
    //   }
    //   p {
    //     font-size: 16px;
    //   }
    // }

    @media (max-width: 600px) {
      padding: 20px !important;
      h2 {
        font-size: 48.4px !important;
      }
    }
  }
  @media (max-width: 600px) {
    padding: 20px !important;
    &::before {
      width: 100%;
      background: #000;
      opacity: 0.4;
      z-index: -1;
    }
  }
  @media (max-width: 400px) {
    padding: 14px !important;
  }
}

.ant-modal-body {
  padding: 0 !important;
}

.ant-modal {
  width: calc(100% - 70px) !important;
  max-width: 1100px;

  padding: 0 !important;
  border-radius: 18px !important;
  // overflow: hidden;
  .ant-modal-close {
    transform: translate(50%, -50%);
    .ant-modal-close-x {
      // width: 32px;
      // height: 31px;
      width: 46px;
      height: 45px;
      background: #131313;
      border: 0.603774px solid #b0c6ff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
  }
  .ant-modal-content {
    background: transparent !important;
    box-shadow: none !important;
  }
  .ant-modal-body {
    background: #000000;
    border: 1px solid #404358;
    overflow: hidden;
    border-radius: 20px;
  }
  .MuiPaper-root {
    background: transparent !important;
    border: 0 !important;
    padding: 14px !important;
  }
}

.recharts-default-tooltip {
  background: #110a24 !important;
  border-radius: 12px;
  overflow: hidden;
  font-size: 10px !important;
}

.myclosebtn {
  width: 46px !important;
  height: 45px !important;
  background: #131313 !important;
  border: 0.603774px solid #b0c6ff !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  position: absolute;
  right: -20px;
  top: -20px;
  path {
    fill: #fff;
    font-size: 14px;
  }
}

.farmgrid {
  @media (max-width: 600px) {
    width: 100% !important;
    margin: 0px !important;
  }
}

.ticketactionflex {
  // background: teal;
  // margin-left: 4vw;  //test
  // margin-right: 3vw;  //test

  @media (max-width: 500px) {
    margin-left: 60px;  //test
    margin-right: 60px;  //test
    flex-direction: column !important;
    align-items: center !important;
    button {
      margin: 6px 0 !important;
    }
  }
}

.totalprizecarddeto {
  * {
    line-height: 1.5 !important;
  }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: whitesmoke; 
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px darkgray; 
  border-radius: 10px;
}
